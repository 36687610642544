<template>
  <div class="w-full flex grey-bg">
    <div class="email-container mx-auto pt-6 bg-white">
      <div class="mb-6">
        <img src="/images/logo.png" alt="Fairpy Logo" class="mr-auto ml-6" />
      </div>
      <div class="bg-white">
        <img
          src="/images/emails/wishlist-banner-image.png"
          class="w-full h-auto"
        />
        <div class="flex flex-col items-start md:px-8 px-4">
          <h1
            class="text-xl font-display font-bold text-black-light text-center"
          >
            Hey John,
          </h1>
          <p class="text-black-lighter mt-6">
            Dont miss your favourite vehicles in the wishlist.
          </p>
          <p class="text-black-lighter mt-6">
            From sedans to trucks, SUVs and more, you’ve got options at FairPY.
            No matter where you’re going, we’re confident you'll find the right
            ride and win the bid.
          </p>
          <h2
            class="
              uppercase
              text-2xl text-center text-blue
              w-full
              mb-8
              mt-12
              font-extrabold
            "
          >
            Your Favourite Top 5
          </h2>
          <div class="w-full">
            <div v-for="(deal, index) in deals" :key="index">
              <div
                class="
                  flex flex-row
                  w-full
                  items-stretch
                  bg-white
                  transition-all
                  relative
                  mb-8
                "
              >
                <div class="w-6/12 flex-shrink-0 relative block">
                  <img
                    class="absolute object-cover object-center w-full h-full"
                    :src="`/images/cars/squared/${deal.image}`"
                  />
                </div>
                <div class="flex flex-col flex-grow py-3 pl-6 text-black-light">
                  <div class="flex flex-row items-start justify-between">
                    <h2 class="font-semibold text-left text-lg leading-tight">
                      {{ deal.title }}
                    </h2>
                  </div>
                  <div
                    class="
                      flex flex-row
                      items-center
                      text-black-lighter
                      mb-2
                      mt-2
                    "
                  >
                    <div class="flex flex-row items-center text-sm mr-3">
                      <i class="icon small miles mr-2"></i
                      >{{ deal.miles }} miles
                    </div>
                    <div class="flex flex-row items-center text-sm capitalize">
                      <i class="icon small title mr-2"></i>{{ deal.type }}
                    </div>
                  </div>
                  <hr />
                  <div class="flex pt-4 flex-col text-xs text-black-lighter">
                    <p class="mb-1 capitalize">
                      Car Details: {{ deal.details }}
                    </p>
                    <p class="mb-1">Bid Final Date: {{ deal.date }}</p>
                    <p>Current Bid Value: ${{ deal.currentBid }}</p>
                  </div>
                  <hr class="hidden lg:block" />
                  <div class="flex flex-row mt-3 items-center justify-between">
                    <div class="text-blue leading-3 relative">
                      <p class="font-semibold text-lg">${{ deal.bid }}</p>
                      <span class="text-xs mt-1 text-black-light"
                        >Fair Bid</span
                      >
                    </div>
                    <div class="text-right leading-tight">
                      <p class="font-normal">${{ deal.savings }}</p>
                      <div
                        class="
                          text-black-lighter
                          cursor-pointer
                          flex-row
                          lg:flex
                          hidden
                          items-center
                          text-xs
                        "
                      >
                        Savings*
                      </div>
                    </div>
                    <div class="ml-2">
                      <button
                        class="
                          bg-blue
                          p-2
                          rounded-sm
                          uppercase
                          text-white text-sm
                          px-4
                        "
                      >
                        Bid Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="w-full my-8" />
      <div class="text-center w-full">
        <p class="font-bold text-lg">Looking for some more?</p>
        <p class="text-2xl font-bold text-blue font-display mt-2">
          We still have huge varieties
        </p>
        <button
          class="
            bg-blue
            p-2
            rounded-sm
            uppercase
            text-white text-sm
            px-8
            mt-6
            mb-8
          "
        >
          Visit Website
        </button>
      </div>
      <div class="w-full bg-blue-dark p-8">
        <p class="text-xs text-white opacity-70 text-center">
          880 SW 15th St #3, Forest Lake, MN 55025, USA<br />
          Copyright © 2021 FairPY, All rights reserved.
        </p>
        <p class="text-xs text-white opacity-70 text-center mt-8">
          You received this email on February 08, 2022 because you are
          registered on FairPY's mailing list
          <a class="underline" href="">Unsubscribe</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data: function () {
    return {
      currentIndex: 0,
      deals: [
        {
          id: 1,
          title: "2017 Mercedes CLA",
          miles: 25340,
          bid: "18,178",
          savings: "3,150",
          bookValue: "15,000",
          image: "1.png",
          type: "Salvage",
        },
        {
          id: 2,
          title: "2017 Lexus-AMG C63",
          miles: 25340,
          bid: "18,178",
          savings: "3,150",
          bookValue: "15,000",
          image: "2.png",
          type: "Salvage",
        },
        {
          id: 3,
          title: "2019 Toyota Landcruiser",
          miles: 25340,
          bid: "18,178",
          savings: "3,150",
          bookValue: "15,000",
          image: "3.png",
          type: "Salvage",
        },
        {
          id: 4,
          title: "2016 BMW X5 WAGON",
          miles: 25340,
          bid: "18,178",
          savings: "3,150",
          bookValue: "15,000",
          image: "4.png",
          type: "Salvage",
        },
        {
          id: 5,
          title: "2017 Lexus NX",
          miles: 25340,
          bid: "18,178",
          savings: "3,150",
          bookValue: "15,000",
          image: "5.png",
          type: "Salvage",
        },
        {
          id: 6,
          title: "2019 BMW 7 Series",
          miles: 25340,
          bid: "$18,178",
          savings: "$3,150",
          bookValue: "$15,000",
          image: "6.png",
          type: "Salvage",
        },
      ],
    };
  },
};
</script>

<style scoped>
body,
.grey-bg {
  background: #f7f7f7;
}
@media screen and (min-width: 769px) {
  .email-container {
    width: 600px;
  }
}
@media screen and (max-width: 768px) {
  .email-container {
    width: 90%;
  }
}
</style>